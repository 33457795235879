import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/meta/engineering-management.png'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import MosaicBlock from '../components/blocks/MosaicBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import VideoBlock from '../components/blocks/VideoBlock'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/business-outcomes/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/org-insights-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/surveys-670.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/issue-summary-670.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    videoThumbnail: file(
      relativePath: {
        eq: "images/product/overview/product-overview-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
  }
`

const ManagementPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="The modern alternative to engineering management platforms"
      variant="dark"
      isNew
      description="Swarmia is the modern alternative to old-school engineering management platforms. It gives everyone in the engineering organization the visibility and tools they need to ship better software faster."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>The modern alternative to engineering management platforms</>}
        content="While traditional engineering management platforms are limited to top-down dashboards, Swarmia gives everyone in the software organization the visibility and tools they need to ship better software faster."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading="Forget about old-school engineering management"
        content="Modern software organizations need a more holistic, transparent, and actionable platform for improving developer productivity."
      />
      <MosaicBlock
        title={<>Empower everyone with&nbsp;the&nbsp;right data</>}
        content={
          <>
            Getting a high-level overview of engineering metrics and the
            progress of cross-team initiatives is crucial for engineering
            leaders.
            <br />
            <br />
            In addition to a wide variety of leadership views, Swarmia also
            serves managers and software engineers the detailed insights they
            need to improve developer productivity in practice.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title="Combine system metrics with developer experience surveys"
        content={
          <>
            Instead of only surfacing system metrics, Swarmia allows you to
            complement them with qualitative feedback from developer experience
            surveys.
            <br />
            <br />
            This way, you can use the quantitative data to identify problem
            areas and the survey responses to come up with concrete action
            points.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title={<>Never miss out on valuable&nbsp;insights</>}
        content={
          <>
            Whether you’re the CTO, a software engineer, or anything in between,
            Swarmia not only gives you access to relevant dashboards but also
            proactively highlights information that you’re likely to find
            useful.
            <br />
            <br />
            With Swarmia, you can worry less about analyzing the data and more
            about taking the right action at the right time.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
      />
      <QuoteBlock person="maryanna" />
      <CTABlock title="Get started with Swarmia today." />
      <LeadBlock
        heading="See Swarmia in action"
        content="Watch our product overview to understand how some of the best software teams in the world use Swarmia to ship better software faster."
      />
      <VideoBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        thumbnail={getImage(data.videoThumbnail)!}
        videoId="ZHcQO20bL4w"
      />
      <CTABlock title="Ready to experience the modern alternative to engineering management?" />
    </Layout>
  )
}

export default ManagementPage
